import { render, staticRenderFns } from "./TableDownload.vue?vue&type=template&id=409054c2&scoped=true"
import script from "./TableDownload.vue?vue&type=script&lang=js"
export * from "./TableDownload.vue?vue&type=script&lang=js"
import style0 from "./TableDownload.vue?vue&type=style&index=0&id=409054c2&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409054c2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('409054c2')) {
      api.createRecord('409054c2', component.options)
    } else {
      api.reload('409054c2', component.options)
    }
    module.hot.accept("./TableDownload.vue?vue&type=template&id=409054c2&scoped=true", function () {
      api.rerender('409054c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/documents/download/components/TableDownload.vue"
export default component.exports