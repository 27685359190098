<template>
  <v-main>
    <HeaderDownload />
    <TableDownload />
  </v-main>
</template>
<script>
import HeaderDownload from './components/HeaderDownload.vue';
import TableDownload from './components/TableDownload.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Download',
  components: { HeaderDownload, TableDownload },
  async created() {
    this.initDocument(this.userInfo.id);
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    ...mapActions('documents', ['initDocument', 'setPaginationDownloads', 'exportDownload']),
  },
};
</script>
<style scoped></style>
