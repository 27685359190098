<template>
  <div class="d-flex flex-column" id="headerDownload">
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <span class="material-icons-outlined" id="arrowId"> keyboard_arrow_right </span>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item icon v-if="item.title === 'Home'" :href="item.href" :disabled="item.disabled">
          <v-btn fab x-large id="homeButton" class="shadow-none" :ripple="false">
            <span class="material-icons-outlined shadow-none textColor--text"> home </span>
          </v-btn>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" v-else>
          <span class="downloadBreadTitle"> {{ item.title }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-row class="my-0 pt-8 pb-8">
      <v-col cols="12" class="py-0">
        <h2 class="titleColor--text">Download</h2>
        <p>Downloads de relatórios extraídos</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'HeaderDownload',
  data() {
    return {
      items: [
        {
          title: 'Home',
          disabled: false,
          href: '/home',
        },
        {
          title: 'Documentos',
          disabled: true,
          href: 'download',
        },
        {
          title: 'Download',
          disabled: true,
          href: 'download',
        },
      ],
    };
  },
};
</script>
<style scoped>
#headerDownload {
  padding-top: 108px !important;
}
#headerDownload .v-breadcrumbs {
  padding-left: 5px !important;
  max-height: 36px !important;
  flex-direction: row;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
}
#arrowId {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 4px 5.53px;
  justify-content: center;
  align-items: center;
  color: #8d8d99 !important;
  pointer-events: none !important;
}
.material-icons-outlined {
  font-size: 16px;
  font-weight: 400 !important;
}
#homeButton {
  width: 32px;
  height: 32px;
  border-radius: 28px !important;
  background-color: transparent;
  box-shadow: none !important;
  color: #2d5796 !important;
}
#homeButton:hover .material-icons-outlined {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
#homeButton:hover {
  border-radius: 28px !important;
  background-color: #e3ffee;
  box-shadow: none !important;
  outline: none !important;
}
.downloadBreadTitle {
  padding: 8px 16px !important;
  color: #8d8d99 !important;
  font-weight: 700 !important;
}
#headerDownload h2 {
  font-size: 32px !important;
  color: var(--v-primary-base);
  font-weight: bold !important;
}
#headerDownload p {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: 400 !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}
</style>
