var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column", attrs: { id: "headerDownload" } },
    [
      _c("v-breadcrumbs", {
        attrs: { items: _vm.items },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "material-icons-outlined",
                    attrs: { id: "arrowId" },
                  },
                  [_vm._v(" keyboard_arrow_right ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                item.title === "Home"
                  ? _c(
                      "v-breadcrumbs-item",
                      {
                        attrs: {
                          icon: "",
                          href: item.href,
                          disabled: item.disabled,
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "shadow-none",
                            attrs: {
                              fab: "",
                              "x-large": "",
                              id: "homeButton",
                              ripple: false,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "material-icons-outlined shadow-none textColor--text",
                              },
                              [_vm._v(" home ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-breadcrumbs-item",
                      { attrs: { href: item.href, disabled: item.disabled } },
                      [
                        _c("span", { staticClass: "downloadBreadTitle" }, [
                          _vm._v(" " + _vm._s(item.title)),
                        ]),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-row",
        { staticClass: "my-0 pt-8 pb-8" },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _c("h2", { staticClass: "titleColor--text" }, [_vm._v("Download")]),
            _c("p", [_vm._v("Downloads de relatórios extraídos")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }