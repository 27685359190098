var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { attrs: { id: "tableDownload" } }, [
    _c(
      "div",
      { attrs: { id: "download-container" } },
      [
        _c(
          "v-data-table",
          {
            attrs: {
              loading: _vm.loadingTable,
              headers: _vm.headers,
              options: _vm.pagination,
              items: _vm.downloads,
              page: _vm.pagination.page,
              "server-items-length": _vm.totalDownloads,
              "footer-props": {
                disablePagination:
                  _vm.companiesList.length === 0 || _vm.loadingTable,
                itemsPerPageOptions:
                  _vm.totalDownloads < 50
                    ? [
                        { value: 50, text: "50" },
                        { value: 100, text: "100", disabled: true },
                        { value: 250, text: "250", disabled: true },
                      ]
                    : _vm.totalDownloads < 100
                    ? [
                        { value: 50, text: "50" },
                        { value: 100, text: "100" },
                        { value: 250, text: "250", disabled: true },
                      ]
                    : [
                        { value: 50, text: "50" },
                        { value: 100, text: "100" },
                        { value: 250, text: "250" },
                      ],
                disableItemsPerPage:
                  _vm.downloads.length === 0 || _vm.loadingTable,
                itemsPerPageText: "Downloads por página:",
                expandIcon: "mdi-dots-horizontal",
              },
              "no-data-text": "Não há registros de downloads a serem exibidos.",
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.uploadedAt",
                fn: function ({ item }) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("datetime")(item.uploadedAt, {
                            mask: "DD/MM/YYYY",
                            showEmpty: true,
                          })
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.savedAt",
                fn: function ({ item }) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("datetime")(item.uploadedAt, {
                            mask: "DD/MM/YYYY",
                            showEmpty: true,
                          })
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _c("div", [_vm._v(_vm._s(_vm._f("estados")(item.status)))]),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "AGTooltip",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              id: "downloadBtn",
                              disabled:
                                item.status === "PROCESSING" ||
                                item.status === "FAILED",
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportar(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(" mdi-download "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "footer.page-text",
                fn: function (items) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                        " - " +
                        _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                        " de " +
                        _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                        " downloads "
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _vm.loadingTable
              ? _c("template", { slot: "body" }, [
                  _c(
                    "tr",
                    _vm._l(_vm.headers, function (loadItem) {
                      return _c(
                        "td",
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "loaderCss",
                            attrs: { "max-width": loadItem.width },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }