<template>
  <v-card id="tableDownload">
    <div id="download-container">
      <v-data-table
        :loading="loadingTable"
        :headers="headers"
        :options.sync="pagination"
        :items="downloads"
        :page="pagination.page"
        :server-items-length="totalDownloads"
        :footer-props="{
          disablePagination: companiesList.length === 0 || loadingTable,
          itemsPerPageOptions:
            totalDownloads < 50
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100', disabled: true },
                  { value: 250, text: '250', disabled: true },
                ]
              : totalDownloads < 100
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250', disabled: true },
                ]
              : [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250' },
                ],
          disableItemsPerPage: downloads.length === 0 || loadingTable,
          itemsPerPageText: 'Downloads por página:',
          expandIcon: 'mdi-dots-horizontal',
        }"
        no-data-text="Não há registros de downloads a serem exibidos."
      >
        <template v-slot:item.uploadedAt="{ item }">
          <div>{{ item.uploadedAt | datetime({ mask: 'DD/MM/YYYY', showEmpty: true }) }}</div>
        </template>
        <template v-slot:item.savedAt="{ item }">
          <div>{{ item.uploadedAt | datetime({ mask: 'DD/MM/YYYY', showEmpty: true }) }}</div>
        </template>
        <template v-slot:item.status="{ item }">
          <div>{{ item.status | estados }}</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <AGTooltip>
            <v-btn
              id="downloadBtn"
              :disabled="item.status === 'PROCESSING' || item.status === 'FAILED'"
              color="primary"
              @click="exportar(item)"
            >
              <v-icon small> mdi-download </v-icon>
            </v-btn>
          </AGTooltip>
        </template>
        <template v-slot:footer.page-text="items">
          {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
          {{ loadingTable ? 0 : items.itemsLength }} downloads
        </template>
        <template slot="body" v-if="loadingTable">
          <tr>
            <td v-for="loadItem in headers">
              <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/main.js';
import GedService from '@/services/gedService.js';

export default {
  name: 'TableDownload',
  components: {
    AGTooltip: () => import('../../../commons/AGTooltip.vue'),
  },

  updated() {
    this.customize();
  },

  data() {
    return {
      headers: [
        { text: 'Módulo', value: 'serviceName', width: '200px', align: 'start', sortable: true },
        { text: 'Solicitação', value: 'uploadedAt', width: '200px', align: 'start', sortable: true },
        { text: 'Processamento', value: 'savedAt', width: '200px', align: 'start', sortable: true },
        { text: 'Status', value: 'status', width: '100px', align: 'start', sortable: true },
        { text: 'Ações', value: 'actions', width: '50px', align: 'start', sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters({
      companiesList: 'documents/getCompaniesList',
      downloads: 'documents/getDownloads',
      loadingTable: 'documents/getLoadingDownloadStatus',
      paginationDownloads: 'documents/getPaginationDownloads',
      totalDownloads: 'documents/getTotalDownloads',
      blobDownload: 'documents/getBlobDownload',
    }),
    pagination: {
      get: function () {
        return this.paginationDownloads;
      },
      set: function (value) {
        this.setPaginationDownloads(value);
      },
    },
  },
  methods: {
    ...mapActions('documents', ['setPaginationDownloads', 'exportDownload']),

    async exportar(item) {
      let file = await GedService.files.get(item.gedId);
      const linkSource = 'data:application/octet-stream;base64,' + file.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = item.fileName;
      downloadLink.click();

      // await this.exportDownload({ item: item });

      // let link = document.createElement('a');
      // link.href = window.URL.createObjectURL(this.blobDownload);
      // link.download = `${item.name} - Agnes.xlsx`;
      // link.click();

      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
    customize() {
      let tableLines = document.querySelectorAll('#download-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
    checkDownloadStatus() {
      try {
        this.$store.dispatch('documents/getDownloads');
        this.updateButtonStatus();
      } catch (error) {
        console.error('Erro ao obter downloads:', error);
      }
    },
    updateButtonStatus() {
      this.downloads.forEach(download => {
        if (download.status === 'READY') {
          this.enableButton();
        }
      });
    },
    enableButton() {
      document.getElementById('downloadBtn').disabled = false;
    }
  },
  mounted() {
    let intervalId = null;
    this.$watch('downloads', () => {
      const hasProcessingDownloads = this.downloads.some(download => download.status === 'PROCESSING');

      if (hasProcessingDownloads && !intervalId) {

        intervalId = setInterval(this.checkDownloadStatus, 15000);
      } else if (!hasProcessingDownloads && intervalId) {
 
        clearInterval(intervalId);
        intervalId = null;
      }
    }, { deep: true });
  }
};
</script>
<style scoped>
#tableDownload {
  border: 1px solid #e7e7fa !important;
}
::v-deep .v-data-table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
  white-space: nowrap;
}
::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  white-space: nowrap;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr td.text-start {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #52526b !important;
}
::v-deep .v-data-table tr.v-data-table__empty-wrapper {
  background-color: #f7f7fd !important;
  color: #52526b !important;
}

::v-deep .v-label {
  color: var(--v-primary-base);
}

::v-deep .v-data-footer__select {
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
  margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
  margin: 0px 64px 0px 0px;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}
:v-deep v-btn .v-btn--disabled .v-btn--icon .v-btn--round .v-btn--text .theme--light .v-size--default {
  margin-right: 16px !important;
}
::v-deep .v-label--active {
  color: #575767 !important;
}

::v-deep .v-data-footer {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: #8686a2 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-left: 0px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 8px !important;
  padding: 0;
}
::v-deep .v-data-table__empty-wrapper td {
  text-align: start !important;
  padding-left: 500px !important;
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep .v-input__slot::before {
  background: #ffffff !important;
  color: var(--v-primary-base);
  border: none !important;
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control:hover {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-input__slot {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot::after {
  transform: scaleX(0) !important;
}

::v-deep div.v-input--is-label-active:nth-child(1) > div:nth-child(1) {
  background: #ffffff !important;
  color: var(--v-primary-base);
}

::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
  padding: 0 !important;
}

::v-deep .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}

::v-deep .v-data-footer .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}
::v-deep .v-list-item:hover.v-list-item--link.theme--light {
  background-color: #e3ffee !important;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
  padding: 0 !important;
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  padding: 0 !important;
  border-radius: 100% !important;
}
.loaderCss {
  width: 100%;
  margin-top: 12px;
  margin-left: 15px;
  margin-bottom: 12px;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #ece7f5;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  animation: shine 2s ease infinite;
}
</style>
